// pages/login.js
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useEffect } from "react";
import Spinner from "components/spinner";

export default function Login() {
	const { data: session, status } = useSession({ required: true });
	const router = useRouter();

	useEffect(() => {
		// Only run redirection logic when session is loaded
		if (status === "authenticated" && session) {
			//const redirectUrl = session.user.newUser ? "/onboarding" : "/listings";
			const redirectUrl = "/onboarding";
			console.debug("Navigating user to", redirectUrl);
			router.push(redirectUrl);
		}
	}, [status, session, router]);

	// Show loading state while authentication is being checked
	return <Spinner open={true} />;
}
